import { clearCookies, setCoookie, getCookie } from "@leon-hub/api-sdk";
import { useGraphqlClient } from "@core/app-rest-client";
import { defineStore } from "pinia";
import { BiometricAuthStorageKey } from "@leon-hub/cordova";
import { useLocalStorageManager } from "@leon-hub/local-storage";
import { useInitAppConfigStore, StorageKeys } from "@core/app-settings";
import { isBoolean } from "@leon-hub/guards";
import { Storage } from "@leon-hub/storage";
function useCookies() {
  const api = useGraphqlClient();
  async function set(options) {
    await setCoookie(api, (node) => node.mutations.cookies.setCookie, {
      options: {
        cookie: {
          ...options,
          httpOnly: true
        }
      }
    }, { silent: true });
  }
  async function get(name) {
    const cookies = await getCookie(api, (node) => node.queries.cookies.getCookies, { options: {} }, { silent: true });
    const cookie = cookies.cookies.find((item) => item.name === name);
    return cookie ? cookie.value : void 0;
  }
  async function getAll() {
    const cookies = await getCookie(api, (node) => node.queries.cookies.getCookies, { options: {} }, { silent: true });
    return cookies.cookies;
  }
  async function clear() {
    await clearCookies(api, (node) => node.mutations.cookies.clearCookies, { options: {} }, { silent: true });
  }
  return {
    get,
    getAll,
    set,
    clear
  };
}
const captchaChallengeWasShownKey = "captchaChallengeWasShown";
const LOCAL_STORAGE_LOGIN_TAB_KEY = "web2_login_active-tab";
const useStorageManagerStore = /* @__PURE__ */ defineStore("storage-manager", () => {
  const { themeConfig } = useInitAppConfigStore();
  const cookieManager = useCookies();
  const localStorageManager = useLocalStorageManager();
  const initValues = {};
  if (themeConfig.current !== void 0) {
    initValues[StorageKeys.THEME] = themeConfig.current;
  }
  if (themeConfig.first !== void 0) {
    initValues[StorageKeys.FIRST_THEME] = themeConfig.first;
  }
  async function set(key, value) {
    if (initValues[key] !== value) {
      initValues[key] = value;
      localStorageManager.setItem(key, value);
      await cookieManager.set({
        name: key,
        value
      });
    }
  }
  async function get(key) {
    if (initValues[key] !== void 0) {
      return initValues[key];
    }
    const item = process.env.VUE_APP_PLATFORM_CORDOVA ? localStorageManager.getItem(key) || "" : await cookieManager.get(key) || "";
    initValues[key] = item;
    return item;
  }
  function setTheme(value) {
    return set(StorageKeys.THEME, value);
  }
  async function getTheme() {
    const theme = await get(StorageKeys.THEME);
    return theme.toUpperCase();
  }
  function setFirstTheme(value) {
    return set(StorageKeys.FIRST_THEME, value);
  }
  async function getFirstTheme() {
    const firstTheme = await get(StorageKeys.FIRST_THEME);
    return firstTheme.toUpperCase();
  }
  function setIsAutoTheme(value) {
    return set(StorageKeys.IS_AUTO_THEME, value.toString());
  }
  async function getIsAutoTheme() {
    return await get(StorageKeys.IS_AUTO_THEME) === "true";
  }
  function clearCache() {
    const storageKeysToSave = process.env.VUE_APP_PLATFORM_CORDOVA ? Object.values(BiometricAuthStorageKey) : [];
    localStorageManager.clear(storageKeysToSave);
  }
  return {
    getTheme,
    setTheme,
    getFirstTheme,
    setFirstTheme,
    getIsAutoTheme,
    setIsAutoTheme,
    clearCache,
    // @deprecated
    get,
    // @deprecated
    set
  };
});
const deviceCustomerLoginKey = "deviceCustomerLogin";
const DeviceCustomerLoginStorage = {
  setLogin(value) {
    useLocalStorageManager().setItem(deviceCustomerLoginKey, value);
  },
  remove() {
    useLocalStorageManager().removeItem(deviceCustomerLoginKey);
  },
  getLogin() {
    return useLocalStorageManager().getItem(deviceCustomerLoginKey);
  }
};
function getDeferredDeviceIDStorage(login) {
  return new Storage({
    id: `deferred-device-id-effect:${login}`,
    guard: isBoolean
  });
}
export {
  DeviceCustomerLoginStorage,
  LOCAL_STORAGE_LOGIN_TAB_KEY,
  captchaChallengeWasShownKey,
  getDeferredDeviceIDStorage,
  useCookies,
  useStorageManagerStore
};
