<script lang="ts" setup>
import type { VSpinnerProps } from '../../types';
import { useVSpinner } from '../../composables';
import { VSpinnerColor, VSpinnerSize } from '../../enums';

const props = withDefaults(defineProps<VSpinnerProps>(), {
  size: VSpinnerSize.DEFAULT,
  color: VSpinnerColor.DEFAULT,
});

const {
  viewBox,
  circleSize,
} = useVSpinner(props);
</script>

<template>
  <svg v-auto-id="'Spinner'"
    :class="{
      [$style['spinner']]: true,
      [$style[`spinner--size_${size}`]]: size !== VSpinnerSize.DEFAULT,
      [$style[`spinner--color-${props.color}`]]: color !== VSpinnerColor.DEFAULT,
      [$style['spinner--custom-size']]: customSize,
    }"
    :viewBox="viewBox"
  >
    <g :class="$style['spinner__group']">
      <circle
        :cx="circleSize"
        :cy="circleSize"
        :r="circleSize"
        :class="{
          [$style['spinner__circle']]: true,
          [$style['spinner__circle--bg']]: true,
        }"
      />
      <circle
        :cx="circleSize"
        :cy="circleSize"
        :r="circleSize"
        :class="{
          [$style['spinner__circle']]: true,
          [$style['spinner__circle--path']]: true,
        }"
      />
    </g>
  </svg>
</template>

<style lang="scss" module>
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1%, 310%;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 220%, 310%;
    stroke-dashoffset: -90%;
  }

  100% {
    stroke-dasharray: 220%, 310%;
    stroke-dashoffset: -302%;
  }
}

.spinner {
  $self: &;

  position: relative;
  width: 20px;
  height: 20px;
  overflow: visible;
  color: var(--TextSecondary);

  &--size {
    &_small {
      width: 12px;
      height: 12px;
    }

    &_large {
      width: 52px;
      height: 52px;
    }
  }

  &--color {
    &-branded {
      #{$self}__circle--path {
        stroke: var(--BrandDefault);
      }

      #{$self}__circle--bg {
        opacity: 1;
        stroke: var(--Highlight);
      }
    }

    &-parent {
      color: currentcolor;

      #{$self}__circle--path {
        stroke: currentcolor;
      }

      #{$self}__circle--bg {
        stroke: currentcolor;
      }
    }
  }

  &__group {
    transform-origin: center;
    animation: rotate 2s linear infinite;
  }

  &__circle {
    fill: none;
    stroke: var(--TextSecondary);
    stroke-width: 8px;

    &--bg {
      opacity: 0.3;
    }

    &--path {
      opacity: 0.95;
      stroke-dasharray: 1%, 310%;
      stroke-dashoffset: 0;
      transform-origin: center;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  &--custom-size &__circle {
    stroke-width: 5px;
  }
}
</style>
