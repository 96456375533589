import debounce from "lodash/debounce";
import { onBeforeUnmount, onDeactivated, onActivated } from "vue";
function useDebounce(func, wait, options) {
  let debouncedFn = debounce(func, wait, options);
  const cancel = () => debouncedFn == null ? void 0 : debouncedFn.cancel();
  const flush = () => debouncedFn == null ? void 0 : debouncedFn.flush();
  function pause() {
    cancel();
    debouncedFn = void 0;
  }
  function resume() {
    debouncedFn = debounce(func, wait, options);
  }
  onBeforeUnmount(pause);
  onDeactivated(pause);
  onActivated(resume);
  const wrapper = function debouncedWrapper(...args) {
    if (debouncedFn) {
      return debouncedFn.apply(this, args);
    }
    return void 0;
  };
  wrapper.cancel = cancel;
  wrapper.flush = flush;
  return wrapper;
}
export {
  useDebounce
};
