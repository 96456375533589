import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { VSpinnerProps } from '../types';
import { VSpinnerSize } from '../enums';

export interface VSpinnerComposable {
  viewBox: ComputedRef<string>;
  circleSize: ComputedRef<string>;
}

export function useVSpinner(props: VSpinnerProps): VSpinnerComposable {
  const spinnerSize = computed(() => (props.size === VSpinnerSize.LARGE ? 52 : 24));
  const viewBox = computed(() => `0 0 ${spinnerSize.value * 2} ${spinnerSize.value * 2}`);
  const circleSize = computed(() => `${spinnerSize.value}px`);

  return {
    viewBox,
    circleSize,
  };
}
