<script lang="ts" setup>
import { ObserveVisibility } from 'vue3-observe-visibility';

import type { VLogoLoaderProps } from './types';
import { useVLogoLoader } from './composables/useVLogoLoader';

const props = withDefaults(defineProps<VLogoLoaderProps>(), {});

const {
  visibilityChanged,
  iconPath,
} = useVLogoLoader(props);

const vObserveVisibility = ObserveVisibility;
</script>

<template>
  <img v-auto-id="'VLogoLoader'"
    v-observe-visibility="{
      callback: visibilityChanged,
    }"
    :class="{
      [$style['logo-loader']]: true,
      [$style['logo-loader--small']]: isSmall,
    }"
    :src="iconPath"
    alt=""
  >
</template>

<style lang="scss" module>
.logo-loader {
  width: 100%;
  height: 100%;
  fill: var(--Highlight);

  &--small {
    width: 60%;
  }
}
</style>
