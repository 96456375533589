import type { ComputedRef } from 'vue';
import { computed, ref } from 'vue';

import { useTheme } from '@core/theme';

import type { VLogoLoaderProps } from '../types';
import { getMascotLogoByName } from '../../../utils/getMascotLogoByName';

export interface VLogoLoaderComposable {
  visibilityChanged(value: boolean): void;
  iconPath: ComputedRef<string>;
}

export function useVLogoLoader(props: VLogoLoaderProps): VLogoLoaderComposable {
  const isVisible = ref(false);

  const { isLight } = useTheme();

  function visibilityChanged(value: boolean): void {
    isVisible.value = value;
  }

  const iconPath = computed(() => {
    let name = 'mascot';

    if (!props?.isAnimationDisabled && isVisible.value) {
      name += '-animated';
    }

    if (isLight.value || props.isFixedLightLogo) {
      name += '--light';
    }

    if (isLight.value && props.isGradient) {
      name += '-gradient';
    }

    return getMascotLogoByName(name);
  });

  return {
    iconPath,
    visibilityChanged,
  };
}
