import { onComponentActivated, onComponentDeactivated } from "@leon-hub/vue-utils";
import { defineStore } from "pinia";
import { ref, toRef, computed, watch, reactive } from "vue";
import { themeHeaderName } from "@leon-hub/api";
import { isTheme } from "@leon-hub/api-guards";
import { themeDark, themeLight, themeAuto } from "@leon-hub/api-sdk";
import { getAutoTheme, changeNavbarColor } from "@leon-hub/cordova";
import { Events } from "@leon-hub/yandex-metrika";
import { useAnalytics, useAnalyticsCommonDataStore } from "@core/analytics";
import { setApiHeaders } from "@core/app-rest-client";
import { useInitAppConfigStore } from "@core/app-settings";
import { useSiteConfigStore } from "@core/site-config";
import { useStorageManagerStore } from "@core/storage";
const allThemes = [
  themeDark,
  themeLight
];
const useThemeStore = /* @__PURE__ */ defineStore("theme", () => {
  const storageManager = useStorageManagerStore();
  const initAppConfigStore = useInitAppConfigStore();
  const analytics = useAnalytics();
  function getInitTheme(key) {
    const { default: defaultTheme } = initAppConfigStore.themeConfig;
    const initTheme = initAppConfigStore.themeConfig[key];
    if (initTheme && isTheme(initTheme)) {
      return initTheme;
    }
    return defaultTheme;
  }
  const siteConfigStore = useSiteConfigStore();
  const currentTheme = ref(getInitTheme("current"));
  const customTheme = ref();
  const firstTheme = ref(getInitTheme("first"));
  const isAuto = ref(false);
  const customStatusBarTheme = ref();
  const settingsBlock = toRef(siteConfigStore, "settingsBlock");
  const theme = computed(() => customTheme.value ?? currentTheme.value);
  const isDark = computed(() => theme.value === themeDark);
  const isLight = computed(() => theme.value === themeLight);
  const isThemeFooterSwitcherEnabled = computed(() => {
    var _a;
    return !!((_a = settingsBlock.value) == null ? void 0 : _a.themeFooterSwitcherEnabled);
  });
  const hasCustomTheme = computed(() => !!customTheme.value);
  let systemTheme;
  async function getDefaultSystemTheme() {
    if (!systemTheme) {
      if (process.env.VUE_APP_PLATFORM_CORDOVA) {
        systemTheme = await getAutoTheme();
      } else {
        systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches ? themeDark : themeLight;
      }
    }
    return systemTheme;
  }
  function setCustomStatusbarTheme(value) {
    customStatusBarTheme.value = value;
  }
  function setCustomStatusbarColor(value = false) {
    setCustomStatusbarTheme(value ? themeDark : void 0);
  }
  async function setTheme(value) {
    if (process.env.VUE_APP_STYLE_SLOTT) {
      value = themeLight;
    }
    currentTheme.value = value;
    setThemeHeaderAndChangeNavbarColor();
    await storageManager.setTheme(value);
  }
  function setThemeHeaderAndChangeNavbarColor() {
    setApiHeaders({
      [themeHeaderName]: theme.value
    });
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      const isLightTheme = theme.value === themeLight;
      const hex = isLightTheme ? process.env.VUE_APP_NAVBAR_COLOR_LIGHT : process.env.VUE_APP_NAVBAR_COLOR_DARK;
      if (hex) {
        changeNavbarColor(hex, isLightTheme);
      }
    }
  }
  async function setFirstTheme(value) {
    await storageManager.setFirstTheme(value);
    firstTheme.value = value;
  }
  async function setIsAutoTheme(value) {
    isAuto.value = value;
    await storageManager.setIsAutoTheme(value);
  }
  async function syncState() {
    const [storedTheme, firstThemeResponse, isAutoResponse] = await Promise.all([
      storageManager.getTheme(),
      storageManager.getFirstTheme(),
      storageManager.getIsAutoTheme()
    ]);
    let firstThemeValue;
    if (isAutoResponse) {
      const defaultSystemTheme = await getDefaultSystemTheme();
      await setIsAutoTheme(true);
      await setTheme(defaultSystemTheme);
      if (!firstThemeResponse) {
        firstThemeValue = defaultSystemTheme;
      }
    } else if (isTheme(storedTheme) && allThemes.includes(storedTheme)) {
      await setTheme(storedTheme);
      if (!firstThemeResponse) {
        firstThemeValue = storedTheme;
      }
    }
    if (firstThemeValue) {
      await setFirstTheme(firstThemeValue);
      const formattedTheme = firstThemeValue.toLowerCase();
      analytics.push(Events.Z_AB_TEST_NEW_USERS, {
        ab_test_light_mode: {
          [formattedTheme]: {
            visit: formattedTheme
          }
        }
      });
    }
  }
  async function recalculateTheme() {
    systemTheme = void 0;
    return syncState();
  }
  function toggleTheme() {
    const newTheme = currentTheme.value === themeDark ? themeLight : themeDark;
    analytics.push(Events.CLICK_MAP, {
      clickCounter: {
        changeMode: newTheme === themeLight ? "light-dark" : "dark-light"
      }
    });
    void setIsAutoTheme(false);
    void setTheme(newTheme);
  }
  async function changeTheme(value) {
    let newTheme = value;
    if (value === themeAuto) {
      newTheme = await getDefaultSystemTheme();
      void setIsAutoTheme(true);
    } else {
      void setIsAutoTheme(false);
    }
    return setTheme(newTheme);
  }
  function setCustomTheme(value) {
    customTheme.value = value;
    setThemeHeaderAndChangeNavbarColor();
  }
  void syncState();
  const analyticsStore = useAnalyticsCommonDataStore();
  watch(theme, (newValue) => {
    analyticsStore.setTheme(newValue);
  }, { immediate: true });
  return {
    theme,
    firstTheme,
    isAuto,
    isDark,
    isLight,
    customStatusBarTheme,
    hasCustomTheme,
    recalculateTheme,
    setCustomStatusbarTheme,
    setCustomStatusbarColor,
    toggleTheme,
    changeTheme,
    setCustomTheme,
    isThemeFooterSwitcherEnabled
  };
});
function useCustomTheme(theme) {
  const { setCustomTheme } = useThemeStore();
  onComponentActivated(() => {
    setCustomTheme(theme);
  });
  onComponentDeactivated(() => {
    setCustomTheme();
  });
}
const useThemeColorStore = /* @__PURE__ */ defineStore("theme-color", () => {
  const customThemeColor = ref();
  let watcher;
  function startThemeColorWatcher(type) {
    if (watcher) {
      throw new Error("Custom theme color change handler already registered!");
    }
    watcher = watch(type, (newValue) => {
      customThemeColor.value = newValue;
    }, { immediate: true });
  }
  function stopThemeColorWatcher() {
    if (watcher) {
      watcher();
      watcher = void 0;
    }
    customThemeColor.value = void 0;
  }
  return {
    customThemeColor,
    startThemeColorWatcher,
    stopThemeColorWatcher
  };
});
function useCustomThemeColor(type, condition) {
  const { startThemeColorWatcher, stopThemeColorWatcher } = useThemeColorStore();
  if (!condition) {
    onComponentActivated(() => {
      startThemeColorWatcher(type);
    });
  } else {
    watch(condition, (newValue) => {
      if (newValue) {
        startThemeColorWatcher(type);
      } else {
        stopThemeColorWatcher();
      }
    }, { immediate: true });
  }
  onComponentDeactivated(() => {
    stopThemeColorWatcher();
  });
}
let themeProvider;
function setCurrentThemeProvider(value) {
  themeProvider = value;
}
function getCurrentThemeProvider() {
  if (!themeProvider) {
    throw new Error("Theme provider is undefined. Use setCurrentThemeProvider method to setup current provider instance or useMockedTheme for tests");
  }
  return themeProvider;
}
function useSetTheme() {
  const { setTheme } = getCurrentThemeProvider().themeSetter;
  return { setTheme };
}
function useTheme() {
  const {
    theme,
    isDark,
    isLight,
    isAuto
  } = getCurrentThemeProvider().themeGetter;
  return {
    theme,
    isDark,
    isLight,
    isAuto
  };
}
const ThemePlugin = {
  install(app, provider) {
    const {
      theme,
      isDark,
      isLight,
      isAuto
    } = provider.themeGetter;
    setCurrentThemeProvider(provider);
    Object.defineProperty(app.config.globalProperties, "$theme", {
      enumerable: true,
      get: () => ({
        theme: theme.value,
        isDark: isDark.value,
        isLight: isLight.value,
        isAuto: isAuto.value
      })
    });
  }
};
function createSimpleThemeGetter(defaultThemeValue = themeDark) {
  const theme = reactive({
    theme: defaultThemeValue,
    isAuto: false
  });
  return {
    themeGetter: {
      theme: toRef(() => theme.theme),
      isDark: computed(() => theme.theme === themeDark),
      isLight: computed(() => theme.theme === themeLight),
      isAuto: toRef(() => theme.isAuto)
    },
    themeSetter: {
      setTheme(value) {
        theme.theme = value;
      }
    }
  };
}
function createStoreThemeGetter(themeStore = useThemeStore()) {
  const isLight = toRef(() => themeStore.isLight);
  const isDark = toRef(() => themeStore.isDark);
  const isAuto = toRef(() => themeStore.isAuto);
  const theme = toRef(() => themeStore.theme);
  return {
    themeGetter: {
      isLight,
      isDark,
      theme,
      isAuto
    },
    themeSetter: {
      setTheme(value) {
        void themeStore.changeTheme(value);
      }
    }
  };
}
export {
  ThemePlugin,
  createSimpleThemeGetter,
  createStoreThemeGetter,
  useCustomTheme,
  useCustomThemeColor,
  useSetTheme,
  useTheme,
  useThemeColorStore,
  useThemeStore
};
